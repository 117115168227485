import React, {useEffect, useState} from 'react';
import axiosInstance from "../http/axios";
import {useTranslation} from "react-i18next";
import usePayPalScript from "../hook/usePaypalScript";

const PayPal = ({ email }) => {

    const[ success, setSuccess ] = useState(null);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const isLoaded = usePayPalScript(process.env.REACT_APP_PAYPAL_ID);


    useEffect(() => {
        if (window.paypal && isLoaded) {
            window.paypal.Buttons({
                createOrder: async (data, actions) => {
                    const response = await axiosInstance.post('/create-order', {email})
                    return response.data.id;
                },
                onApprove: async (data, actions) => {
                    await axiosInstance.post('/capture-order', {orderId: data.orderID})
                    // Handle successful capture here
                    setSuccess(true);
                    setMessage(t('payment_success'));
                },
                onError: (err) => {
                    // Handle errors here
                    setSuccess(false);
                    setMessage(t('payment_error'));
                },
            }).render('#paypal-button-container');
        }
    }, [email, isLoaded]);

    return (
        <>
            { success === true && (<div className="alert alert-success">{message}</div>)}
            { success === false && ( <div className="alert alert-danger">{message}</div>)}
            <div id="paypal-button-container"></div>
        </>
    )
};

export default PayPal