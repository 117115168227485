import React, {useEffect, useState} from "react";

import {ReactComponent as bg } from "./../flag/bg.svg";
import {ReactComponent as de } from "./../flag/de.svg";
import {ReactComponent as el } from "./../flag/el.svg";
import {ReactComponent as es } from "./../flag/es.svg";
import {ReactComponent as fr } from "./../flag/fr.svg";
import {ReactComponent as it } from "./../flag/it.svg";
import {ReactComponent as pl } from "./../flag/pl.svg";
import {ReactComponent as pt } from "./../flag/pt.svg";
import {ReactComponent as ro } from "./../flag/ro.svg";
import {ReactComponent as en } from "./../flag/en.svg";
import {ReactSVG} from "react-svg";

// nota orders count, see languagesCode order
const languages = [{ fr}, {de}, {en}, {el}, {bg}, {pl}, {ro}, {it}, {pt},{es}];
const Flag = ({lang }) => {


    const [ Current , setCurrent ] = useState(languages[0]['fr']);

    useEffect(() => {

        if(lang) {
            languages.forEach((elem, index) => {
                if( lang === Object.keys(elem)[0]) {
                    setCurrent(elem[lang]);
                }
            })
        }

    }, [lang]);

    return (
        <><img src={`/${lang}.png`} style={{ width:'100px',  height:'40px'}} /></>
    )

}
export default Flag;
