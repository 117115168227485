export const isDateBetween = (dateToCheck, startDate, endDate) => {
    const timestampToCheck = dateToCheck.getTime();
    const startTimestamp = startDate instanceof Date ? startDate.getTime() : null;
    const endTimestamp = endDate instanceof  Date ? endDate.getTime(): null;

    return (startTimestamp && endTimestamp ? ( timestampToCheck >= startTimestamp && timestampToCheck <= endTimestamp ) : false );
};
export const formatDateToReadable = (date, local ) => {
    if ( date ) {
        if(!local) local = 'fr-FR';
        date = new Date(date);
        const options = {day: 'numeric', month: 'long', year: 'numeric'};
        return new Intl.DateTimeFormat(local, options).format(date);
    } else {
        return null;
    }
}

export const inXDays = (date, x) => {
    try {
        const _date = new Date(date);
        const inXDays = new Date(date);
        return inXDays.setDate(_date.getDate() + x);
    } catch( err) {
        return null;
    }
}
