import React from "react";
import {useDispatch, useSelector} from "react-redux";

const UserFilterValid = () => {

    const dispatch = useDispatch();
    const valid = useSelector((state) => state.valid);

    const onChange = (e) => {
        dispatch({type: 'SET_ADMIN_VALID', payload: e.target.value});
    }

    return (
        <>
            <select className="form-control" value={valid} onChange={onChange}>
                <option value="0">ALL</option>
                <option value="1">VALIDE</option>
                <option value="2">NON VALIDE</option>
            </select>
        </>
    );
};

export default UserFilterValid;