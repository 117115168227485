import React , {useState} from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axiosInstance from "../http/axios";
import {useTranslation} from "react-i18next";

const Stripe = ({email}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        // Call your backend to create a Payment Intent
        let clientSecret;
        try {
            const response = await axiosInstance.post('/create-payment-intent', {email})
            clientSecret = response.data.clientSecret;
        } catch (error) {

            console.log(error);
            setLoading(false);
            setErrorMessage('email invalid')
            return;

        }

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });

        if (result.error) {
            setErrorMessage(result.error.message);
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                setSuccessMessage(t('payment_success'));
            }
        }

        setLoading(false);

    };

    return (
        <>
            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div> )}
            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div> )}
            <form onSubmit={handleSubmit}>
                <CardElement options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}/>
                <button className="btn btn-dark btn-stripe" type="submit" disabled={!stripe || loading}>
                    {t('pay_with_stripe')}
                </button>
            </form>
        </>
    );
};

export default Stripe;