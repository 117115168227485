import React from "react";
import {useDispatch, useSelector} from "react-redux";

const UserFilterEmail = () => {

    const dispatch = useDispatch();
    const email = useSelector((state) => state.adminEmail);

    const onChange = (e) => {
        dispatch({type: 'SET_ADMIN_EMAIL', payload: e.target.value});
    }

    return (
        <>
            <input className="form-control" onChange={e => onChange(e)} />
        </>
    );
};

export default UserFilterEmail;