import axios from 'axios';
import history from "../history";
import i18n from './../i18n'
import store from "../redux/store";

// Créer une instance d'Axios
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 30000,
    withCredentials: true
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if ( process.env.REACT_APP_TYPE) {
            config.headers['X-Application-Type'] = process.env.REACT_APP_TYPE;
        }
        return config;
    },
    (error) => {

        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        const token =  response.headers['x-token'];
        if(token) {
            window.localStorage.setItem('token', token);
        }
        return response;
    },
    (error) => {
        store.dispatch({type: 'ON_ERROR', payload: error});
        return Promise.reject(error);
    }
);


export default axiosInstance;