import React, {useEffect, useState} from "react";

const usePayPalScript = (clientId) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [count , setCount] = useState(0);

    useEffect(() => {

        if (window.paypal) {
            setScriptLoaded(true);
            return;
        }


        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=EUR`;
        script.async = false; //with true was bugging
        script.onload = () => setScriptLoaded(true);
        script.onerror = (error) => {
            setScriptLoaded(false);
        }



        document.body.appendChild(script);
        // Cleanup on unmount
        return () => {
            document.body.removeChild(script);
        };
    }, [clientId]);

    return scriptLoaded;
};

export default usePayPalScript;