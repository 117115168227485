import React, {useEffect, useState} from "react";
import axiosInstance from "../http/axios";
import {useTranslation} from "react-i18next";

const PremiumAmount = () => {

    const [amount , setAmount ] = useState(0);
    const { t } = useTranslation();


    useEffect(() => {
        try {
            axiosInstance.get('/amount').then((response) => {
                if (response.data.success) {
                    setAmount(Number(response.data.amount) / 100);
                }
            })
        } catch(error) {
            console.log(error);
        }
    }, [])

    return (<h4>
        {t('pay')} {amount} &euro;
    </h4>)
}
export default PremiumAmount;