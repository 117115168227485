const languageCodes = [
    'fr',
    'de',
    'en',
    'el',
    'bg',
    'pl',
    'ro',
    'it',
    'pt',
    'es'
];
export default languageCodes;