import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <div className="links">
                <h4 className="title">Useful links</h4>
                <div className="links-container">
                    <div className="links">
                        <ul>
                            <li><a href="#">Help and FAQ</a></li>
                            <li><a href="#">Pricing</a></li>
                        </ul>
                    </div>
                    <div className="links">
                        <ul>
                            <li><a href="#">How does it works ?</a></li>
                            <li><a href="#">Use condition</a></li>
                        </ul>
                    </div>
                    <div className="links">
                        <ul>
                            <li><a href="#">RGPD</a></li>
                            <li><a href="#">Mentions Légales</a></li>
                            <li><a href="#">Toolbox</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-copyright">
                <div className="copyright">copyright site 2024</div>
            </div>
        </div>)
}
export default Footer;