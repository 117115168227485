import React from "react";
import QuestionForm from "../components/QuestionForm";

const Contact = () => {
    return (
        <div className="page">
            <QuestionForm isCaptcha={true}></QuestionForm>
        </div>
    )
}
export default Contact;