import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCountryOptions} from "../../country/country";

const UserFilterCountry = () => {

    const dispatch = useDispatch();
    const country = useSelector((state) => state.country);

    const countryOptions = getCountryOptions('fr');


    const onChange = (e) => {
        dispatch({type: 'SET_ADMIN_COUNTRY', payload: e.target.value});
    }

    return (
        <>
            <select className="form-control" value={country} onChange={onChange}>
                <option value="ALL">TOUS</option>
                {countryOptions.map(({code, name}) => (
                    <option key={code} value={code} label={name}/>
                ))}
            </select>
        </>
    );
};

export default UserFilterCountry;