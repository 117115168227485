import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import routes from "./route/routes";
import history from "./history";
import NotFoundPage from "./page/NotFound";
import Login from "./page/Login";
import Footer from "./components/Footer";
import Header from "./components/Header";
const codes = require('./lang/codes').default;


const addLangToRoute = (lang, url) => {
    // Supprimer le '/' final de l'URL si présent
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    // Ajouter le code de langue au début de l'URL
    return `/${lang}${url}`;
}

function App() {

    const application_type = process.env.REACT_APP_TYPE;


    let _elements = [];
    routes.forEach((element) => {
        if(element.for.includes(application_type)) {
            codes.forEach((lang) => {
                _elements.push({path: addLangToRoute(lang, element.path), element: element.element});
            })
        }
    });

    return (
        <HelmetProvider>
             <Router history={history}>
               <Header></Header>
                 <div className={`container-app-login`}>
                   <Routes>
                       <Route path={ "/" } element={<Login />} />
                       <>{_elements.map((element, index) => {
                           return (<Route key={index} path={element.path} element={element.element} />)
                        })
                       }</>
                       <Route path={'*'} element={<NotFoundPage/>}></Route>
                   </Routes>
                 </div>
             </Router>
            <Footer></Footer>

        </HelmetProvider>
    );
}

export default App;
