import React, {useEffect, useRef, useState} from 'react';
import { Line } from 'react-chartjs-2';
import LegendAbs  from "./LegendAbs";
import verticalLine from "./verticalLine";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';
import useHasChanged from "../hook/useHasChanged";
import {useMediaQuery} from "react-responsive";




ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    LegendAbs,
    PointElement,
    LineElement,
    verticalLine,
);


const LineChart = ({label, data, title, legend, horizon, unity }) => {
    const [legendParams, setLegendParams ] = useState({})

    const reload = useHasChanged(legendParams);

    const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

    const chartRef = useRef(null);

    useEffect(() => {
        const resizeListener = () => {
            if (chartRef?.current) {
                chartRef?.current.resize();

            }
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [reload, chartRef]);

    useEffect(() => {
        if(horizon !== '15d') {
            setLegendParams({ ...legendParams, display: false});
        }
    }, [horizon])

    const dataC = {
        labels: label,
        datasets: [
            {
                label: title,
                data: data,
                fill: false,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                pointRadius: 1,
                pointHoverRadius: 1

            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            verticalLine: {
                setLegendParams: setLegendParams,
                legendParams : legendParams,
                reload,
                horizon,
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        return `${context[0].dataset.label}`;
                    },
                    label: (context) => {
                        return `${context?.dataset?.data[context?.dataIndex]} ${unity}`;
                    }
                }
            },

        },
        interaction: {
            mode: 'nearest',
            intersect: false
        },
        scales: {
            x: {
                ticks: {
                    // Control label display options
                    callback: function (value,i,a ,c) {
                        if(((value % 3) === 0)) {
                            if(horizon === '1d') {
                                return label[value].time;
                            } else if(horizon === '15d') {
                                return label[value].date;
                            }
                        } else {
                            return '';
                        }

                    },
                    maxRotation:    0, // Rotate labels if necessary
                    minRotation: 0,
                },
            },
            y: {
                title: {
                    display: true,
                    text: legend,
                    font: {
                        size: 20,
                        weight: 'bold',
                        lineHeight: 1.2
                    }
                },
                min: 0, // Explicitly start Y-axis at zero
                ticks: {
                    callback: function (value) {
                        return value; // Format tick labels if needed
                    },
                },
            }

        },
    };



    return (
        <div>
            <LegendAbs params={legendParams}></LegendAbs>
            <Line ref={chartRef} data={dataC} options={options} height={isMobile? 700: 500}/>
        </div>
    );
};
export default LineChart;