import React, {useEffect, useState} from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from "../http/axios";
import {useTranslation} from "react-i18next";

const QuestionForm = ({isCaptcha}) => {

    const { t } = useTranslation();

    const [data, setData ] = useState({a: 0, b: 0});
    const [postData, setPostData ] = useState({});
    const [error , setError ] = useState(null);
    const [ message, setMessage ] = useState('');
    const [ reload, setReload ] = useState(1);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/random');
                setData(response.data);
            } catch(error) {

            }
        }
        fetchData();
    }, [reload]);


    let shape = {
        tel: Yup.string()
            .required(('phone_required')),
        message: Yup.string()
            .required(('required')),
    }
    let urlApi = '/message';
    if(isCaptcha !== false) {
        shape['somme'] = Yup.string().required(t('required'));
        shape['email'] = Yup.string().email(('invalid_email')).required(('required'));

    } else {
        shape['somme'] = Yup.string();
        shape['email'] = Yup.string();
        urlApi = '/api' + urlApi;
    }
    const SignupSchema = Yup.object().shape(shape);

    return (
        <>
            { error && (<div className="alert alert-danger">{error}</div>)}
            { message && (<div className="alert alert-success">{message}</div>)}
            <Formik
                initialValues={{ somme: '', email : '', tel: '', message : '' }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                        setError(null);
                        setMessage(null);
                        axiosInstance.post(urlApi, values).then((response) => {

                            if(response.data.success) {
                                setReload(reload + 1 );
                                setMessage(t('message_send'));
                            } else {

                            }
                            setSubmitting(false);
                            resetForm();
                        }).catch((error) => {
                            if( error.response.status === 418) setError(t('i_am_a_teapot'));
                            setSubmitting(false);
                        })


                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        { isCaptcha !== false && (<><div className="form-group">
                            <label htmlFor="somme">{t('what_are')} : {data.a} + {data.b}</label>
                            <Field type="text" className="form-control" name="somme" />
                            <ErrorMessage name="somme" component="div" className="invalid-feedback" style={{display:'block'}}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">{t('email')}</label>
                            <Field type="email" name="email" className="form-control"/>
                            <ErrorMessage name="email" component="div" className="invalid-feedback" style={{display:'block'}}/>
                        </div></>)}
                        <div className="form-group">
                            <label htmlFor="tel">{t('phone')}</label>
                            <Field type="text" name="tel" className="form-control"/>
                            <ErrorMessage name="tel" component="div" className="invalid-feedback" style={{display:'block'}}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">{t('message')}</label>
                            <Field as="textarea" className="form-control" rows="10" name="message" />
                            <ErrorMessage name="message" component="div" className="invalid-feedback" style={{display:'block'}}/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-success form-control" type="submit" disabled={isSubmitting}>
                                {t('submit')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
export default QuestionForm;