import React from "react";
import {useTranslation} from "react-i18next";

const LoginComponentError = ({errorCode}) => {

    const { t } = useTranslation();

    switch(errorCode) {
        case 404:
            return (<div className="alert alert-danger">{t('login_error_404')}</div>)
            break;
        case 500:
            return (<div className="alert alert-danger">{t('login_error_500')}</div>)
            break;
        case 401:
            return (<div className="alert alert-danger">{t('login_error_401')}</div>)
            break;
        default:
            return (<></>)
            break;

    }
}
export default LoginComponentError;