import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import axiosInstance from "../http/axios";

const ValidateEmail = () => {

    const { t } = useTranslation();

    const lang = useSelector((state) => state.language);

    const [success, setSuccess] = useState(null)

    const navigate = useNavigate();

    const { token } = useParams();

    const validate = async (e) => {

        await axiosInstance.post('/validate-account?lang=' + lang, {token}).then((response) => {
            if( response.data.success) {
                setSuccess(true);
                navigate('/' + lang + '/login');

            }
        }).catch((error) => {
            setSuccess(false);
        });


    }

    return (
        <>
            <div className="page">
                { success === null ? <button className="btn btn-dark" onClick={e=> validate(e)}>{t('validate_email')}</button>
                    : <div className="alert alert-danger" role="alert">{t('error_validation')}</div> }
            </div>
        </>
    )
}
export default ValidateEmail;