import React, {useEffect, useState} from "react";
import {formToJSON} from "axios";
import parseJwt from "../tools/parseJwt";
import axiosInstance from "../http/axios";
import {useTranslation} from "react-i18next";
import {formatDateToReadable, inXDays} from "../tools/date";
import {useSelector} from "react-redux";

const ValidityDate = () => {

    const [ data, setData ] = useState(null);
    const [message , setMessage] = useState('');

    const { t } = useTranslation();

    const lang = useSelector((state) => state.language);

    useEffect(() => {
        if(parseJwt(window.localStorage.getItem('token'))) {
            axiosInstance.get('/api/validity-date').then((response) => {
                setData(response.data);
            }).catch((error) => {

            })
        }
    }, []);

    useEffect(() => {
        if(data) {

            const local = lang + '-' + data.country;

            if(data.is_free_14d && !data.date_end_premium) {
                setMessage(t('available_from_date_to_date', {
                    startDate : formatDateToReadable(data.createdAt, local ),
                    endDate : formatDateToReadable(inXDays(data.createdAt, 14), local)}));
            }
            if(data.date_start_premium && data.date_end_premium) {
                setMessage(t('available_from_date_to_date', {
                    startDate : formatDateToReadable(data.date_start_premium, local),
                    endDate : formatDateToReadable(data.date_end_premium, local)}));
            }
        }
    }, [data, lang])

    return (<>{data && (<>
        {message && (<h4>{message}</h4>)}
        </>
    )}</>)
}
export default ValidityDate;