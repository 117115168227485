import React from "react";
import UserTab from "../components/user/UserTab";

const User = () => {
    return (
        <div className="admin">
            <UserTab></UserTab>
        </div>
    )
}
export default User;