import React from "react";
import {useParams} from "react-router-dom";
import GetForecast from "../components/GetForecast";


const Graph = () => {

    const { lat, lng } = useParams();


    return (
        <>
            <div className="page"><GetForecast lat={lat} lng={lng}></GetForecast></div>
        </>
    )
}
export default Graph;