import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import axiosInstance from "../http/axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
const BillingEmail = () => {

    const { t, i18n } = useTranslation();

    const [message , setMessage ] = useState('');

    const dispatch = useDispatch();

    const SignupSchema = Yup.object().shape({
        email: Yup.string().email(t('invalid_email')).required(t('required')),
    });

    return(<div>
        <h4>{t('set_your_email_to_pay')}</h4>
        { message && (<div className="alert alert-danger" >{message}</div>)}
        <div>
            <Formik
                initialValues={{ email : '' }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setMessage('');
                    axiosInstance.post('/email-exists', values).then((response) => {

                        if(response.data.success) {
                            dispatch({type: 'SET_EMAIL', payload: values.email});
                        } else {
                            dispatch({type: 'SET_EMAIL', payload: null});
                            setMessage(t('email_does_not_exist'));
                        }
                        resetForm();

                    }).catch((error) => {

                    })
                    setSubmitting(false);

                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="email">{t('email')}</label>
                            <Field type="email" className="form-control" name="email" />
                            <ErrorMessage name="email" component="div" style={{display:'block'}} className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-success form-control" type="submit" disabled={isSubmitting}>
                                {t('submit')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </div>);
};
export default BillingEmail;