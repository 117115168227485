import QuestionForm from "../components/QuestionForm";

const ErrorPage = () => {
    return (
        <div className="_500">
            <div className="info">
                <h1>500</h1>
                <p>There is obviously a bug in the code, thank you to report it !</p>
            </div>
            <div className="message">
                <QuestionForm></QuestionForm>
            </div>
        </div>
    );
};
export default ErrorPage;