import Header from "../components/Header";

const NotFoundPage = () => {
    return (
        <>
            <div className="_404">
                <div className="info">
                    <h1>404</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <a href="/">Go to Home</a>
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;