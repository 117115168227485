import React from "react";
import {useDispatch, useSelector} from "react-redux";

const UserFilterDate = ({type}) => { // one among startOrder and endOrder.

    const dispatch = useDispatch();
    const value = useSelector((state) => state[type]);


    const onChange = (e) => {
        dispatch({type: 'SET_ADMIN_ORDER_' + type.toUpperCase(), payload: e.target.value});
    }

    return (
        <>
            <select className="form-control" value={value} onChange={onChange}>
                <option value="DESC">DESC</option>
                <option value="ASC">ASC</option>
            </select>
        </>
    );
};

export default UserFilterDate;