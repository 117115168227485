import React, {useState} from "react";
import axiosInstance from "../http/axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import LoginComponentError from "./LoginComponentError";

const LoginComponent = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Get specific query parameter
    const redirect = queryParams.get('redirect') || '';

    if(redirect) {
        window.localStorage.setItem('redirect', redirect)
    }
    const { t } = useTranslation();

    const navigate = useNavigate();

    const application_type = process.env.REACT_APP_TYPE;


    const [success, setSuccess] = useState(null);

    const lang = useSelector((state) => state.language);

    const SignupSchema = Yup.object().shape({
        password: Yup.string()
            .required(t('required')),
        email: Yup.string().email(t('invalid_email')).required(t('required')),
    });

    return (
        <>
            <LoginComponentError errorCode={success}></LoginComponentError>
            <Formik
                initialValues={{ password: '', email : '' }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting }) => {
                    axiosInstance.post('/login', values).then((response) => {

                        if (response.data.token) {
                            window.localStorage.setItem('token', response.data.token);
                            const redirect = window.localStorage.getItem('redirect');
                            if(redirect && redirect !== 'null' ) {
                                window.localStorage.setItem('redirect', null);
                                navigate(redirect);
                            } else if (application_type === 'admin') {
                                navigate('/' + lang + '/admin/users');
                            } else {
                                navigate('/' + lang + '/secure/coordinate');
                            }
                        } else {
                            setSuccess(false);
                        }

                    }).catch((error) => {
                        setSuccess(error.response.status);
                    })
                    setSubmitting(false);

                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="form-floating">
                            <Field type="email" className="form-control" name="email" placeholder={t('email')}/>
                            <label htmlFor="email">{t('email')}</label>
                            <ErrorMessage name="email" component="div" style={{display:'block'}} className="invalid-feedback"/>
                        </div>
                        <div className="form-floating">
                            <Field type="password" name="password" className="form-control" placeholder={t('password')}/>
                            <label htmlFor="password">{t('password')}</label>
                            <ErrorMessage name="password" component="div" style={{display:'block'   }} className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-success form-control" type="submit" disabled={isSubmitting}>
                                {t('connect')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}
export default LoginComponent;