import React from "react";
import axiosInstance from "../../http/axios";

const UserAlert = ({display, user , reload, setReload }) => {

    const validateUser = async (userId ) => {
        try {
            const response = await axiosInstance.put('/api/admin/validate-user', {userId });
            if(response.data.success) {
                setReload(reload+1);
            }
            setReload(reload+1);
        } catch( error ) {
            console.log(error);
        }
    }

    return (<>
        { display && (
            <button onClick={e => validateUser(user.id)} className="btn btn-danger">Valider</button>
        )}
    </>)
}
export default UserAlert;