import React, {useState} from "react";
import axiosInstance from "../http/axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
const GenerateToken = () => {

    const lang = useSelector((state) => state.language);

    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [message , setMessage] = useState('');
    const navigate = useNavigate();

    const SignupSchema = Yup.object().shape({
        email: Yup.string().email(t('invalid_email')).required(t('required')),
    });

    return (
        <>
            { error && (<div className="alert alert-danger">{error}</div>)}
            { message  && (<div className="alert alert-success">{message}</div>)}
            <Formik
                initialValues={{  email : '' }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setError('');
                    setMessage('');
                    axiosInstance.post('/generate-token?lang=' + lang, values).then((response) => {

                        if (response.data.success ) {
                            setMessage(t('send_email_forgot_password'));
                            resetForm();
                        }
                        setSubmitting(false);

                    }).catch((error) => {
                        setSubmitting(false);
                        resetForm();
                        if(error.response.status === 404) {
                            setError(t('user_does_not_exist'));
                        } else if ( error.response.status === 400) {
                            setError(t('non_conform_data'));
                        }
                    })
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="email">{t('email')}</label>
                            <Field type="email" className="form-control" name="email" />
                            <ErrorMessage name="email" component="div" style={{display:'block'}} className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-success form-control" type="submit" disabled={isSubmitting}>
                                {t('submit')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>)
};

export default GenerateToken;