import React from "react";
import {Helmet} from "react-helmet-async";
import SubscribeComponent from "../components/SubscribeComponent";

const Subscribe = () => {
    return (
        <>

            <Helmet>
                <title>Api météo</title>
                <meta name="description" content="C'est une page d'api météo" />
                <meta property="og:title" content="API météo" />
                <meta property="og:description" content="C'est une page d'api météo" />

            </Helmet>
            <div className="page">
                <SubscribeComponent></SubscribeComponent>
            </div>
        </>

    )
}
export default Subscribe;