import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import LoginComponentError from "./LoginComponentError";
import {ErrorMessage, Field, Form, Formik} from "formik";
import axiosInstance from "../http/axios";

const AmountComponent = () => {

    const [message , setMessage] = useState('');
    const [error , setError] = useState('');
    const [amount , setAmount ] = useState(null);

    useEffect(() => {
        axiosInstance.get('/api/admin/amount').then((response) => {
            console.log(response);
            if(response.data.success) {
                setAmount(response.data.amount);
            }
        }).catch((error) => {
            setError('Erreur');
        })

    }, [])

    const SignupSchema = Yup.object().shape({
        amount: Yup.string()
            .required(('required')),
    });

    if (amount === null) {
        // You might want to show a loading indicator here
        return <div>Loading...</div>;
    }

    return (
        <>
            {message && (<div className="alert alert-success">{message}</div>)}
            {error && (<div className="alert alert-danger">{error}</div>)}
            <Formik
                initialValues={{ amount: amount }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting }) => {
                    axiosInstance.put('/api/admin/amount', values).then((response) => {
                        if(response.data.success) {
                            setMessage('Montant mis à jour');
                        }
                    }).catch((error) => {
                        setError('Erreur');
                    })
                    setSubmitting(false);

                }}
            >
                {({ isSubmitting }) => (
                    <>
                        <Form>
                            <div className="form-group">
                                <label htmlFor="amount">{('amount')}</label>
                                <Field type="amount" className="form-control" name="amount" />
                                <ErrorMessage name="amount" component="div" style={{display:'block'}} className="invalid-feedback"/>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-success form-control" type="submit" disabled={isSubmitting}>
                                    {('submit')}
                                </button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    )
}
export default AmountComponent;