import React, {useState} from "react";
import axiosInstance from "../http/axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import { getCountryOptions } from "../country/country";
import {useSelector} from "react-redux";

const SubscribeComponent = () => {

    const { t } = useTranslation();

    const [success, setSuccess ] = useState(false);
    const [error, setError ] = useState('');

    const lang = useSelector((state) => state.language);

    const countryOptions = getCountryOptions(lang);


        const gender =  Yup.number().required(t('required'));
        const firstname = Yup.string().required(t('required'));
        const lastname = Yup.string().required(t('required'));
        const password = Yup.string()
            .min(6, t('min_length_password'))
            .required(t('required'));
        const confirm_password = Yup.string()
            .oneOf([Yup.ref('password'), null], t('password_match'))
            .required(t('required'));
        const email = Yup.string().email(t('invalid_email')).required(t('required'));
        const country = Yup.string().required(t('required'));
        const freeFor14 = Yup.boolean();
        const code_company= Yup.string().required(t('required'));


    const application_type = process.env.REACT_APP_TYPE;

    let initialValues = {
        gender: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirm_password: '',
        country: '',
        code_company: '',
        is_free_14d : false,

    };

    let shape;
    switch(application_type) {
        case 'b2b':
            shape = {
                gender,
                firstname,
                lastname,
                email,
                password,
                confirm_password,
                country,
                code_company,
                is_free_14d: freeFor14,
            }
            break;
        case 'b2c':
            shape = {
                gender,
                firstname,
                lastname,
                email,
                password,
                confirm_password,
                country,
                is_free_14d: freeFor14,
            }
            initialValues = {
                gender: '',
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                confirm_password: '',
                country: '',
                is_free_14d : false,

            };break;
        case 'admin':
            shape = {
                email,
                password,
                confirm_password,
            }
            initialValues = {
                email: '',
                password: '',
                confirm_password: '',
            };
            break;
    }

    const SignupSchema = Yup.object().shape(shape);



    return (
        <>  { error && (<div className="alert alert-danger">{error}</div>)}
            { !success ? <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSuccess(false);
                axiosInstance.post('/subscribe?lang=' + lang, values).then((response) => {
                    if(response.data.success) {
                        setSuccess(true);
                        setError('');
                    }
                }).catch((error) => {
                    setError(t('user_exists'));
                    setSuccess(false);
                    resetForm();
                })
                setSubmitting(false);

            }}
        >
            {({isSubmitting}) => (
                <Form>
                    { (application_type === 'b2b' || application_type === 'b2c') && (<div className="form-group">
                        <label htmlFor="email">{t('gender')}</label>
                        <Field as="select" className="form-control" name="gender">
                            <option value="">{t('select_a_gender')}</option>
                            <option value="1">{t('mr')}</option>
                            <option value="2">{t('mss')}</option>
                        </Field>
                        <ErrorMessage name="gender" component="div" style={{display: 'block'}}
                                      className="invalid-feedback"/>
                    </div>) }
                    { (application_type === 'b2b' || application_type === 'b2c') && (
                    <div className="form-group">
                        <label htmlFor="firstname">{t('firstname')}</label>
                        <Field type="text" name="firstname" className="form-control"/>
                        <ErrorMessage name="firstname" component="div" style={{display: 'block'}}
                                      className="invalid-feedback"/>
                    </div>)}
                    { (application_type === 'b2b' || application_type === 'b2c') && (<div className="form-group">
                        <label htmlFor="lastname">{t('lastname')}</label>
                        <Field type="text" name="lastname" className="form-control"/>
                        <ErrorMessage name="lastname" component="div" style={{display: 'block'}}
                                      className="invalid-feedback"/>
                    </div>)}

                    <div className="form-group">
                        <label htmlFor="email">{t('email')}</label>
                        <Field type="text" name="email" className="form-control"/>
                        <ErrorMessage name="email" component="div" style={{display: 'block'}} className="invalid-feedback"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">{t('password')}</label>
                        <Field type="password" name="password" className="form-control"/>
                        <ErrorMessage name="password" component="div" style={{display: 'block'}}
                                      className="invalid-feedback"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirrm_password">{t('confirm_password')}</label>
                        <Field type="password" name="confirm_password" className="form-control"/>
                        <ErrorMessage name="confirm_password" component="div" style={{display: 'block'}}
                                      className="invalid-feedback"/>
                    </div>
                    { (application_type === 'b2b' || application_type === 'b2c') && (
                        <div className="form-group">
                        <label htmlFor="country">{t('country')}</label>
                        <Field as="select" name="country" className="form-control">
                            <option value="">{t('select_a_country')}</option>
                            {countryOptions.map(({code, name}) => (
                                <option key={code} value={code} label={name}/>
                            ))}
                        </Field>
                        <ErrorMessage name="country" component="div" style={{display: 'block'}}
                                      className="invalid-feedback"/>
                    </div>)}
                    { application_type === 'b2b' && (<div className="form-group">
                        <label htmlFor="code_company">{t('code_company')}</label>
                        <Field type="text" name="code_company" className="form-control"/>
                        <ErrorMessage name="code_company" component="div" style={{display: 'block'}}
                                      className="invalid-feedback"/>
                    </div>)}
                    { (application_type === 'b2b' || application_type === 'b2c') && (<div className="form-group">
                        <label>
                            <Field type="checkbox" name="is_free_14d" />
                            {t('try_free_for_14')}
                        </label>
                    </div>)}
                    <div className="form-group">
                        <button className="btn btn-success form-control" type="submit" disabled={isSubmitting}>
                            {t('submit')}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
            : <div className="alert alert-success">{t('subscribe_success')}</div>}
        </>
    )
}
export default SubscribeComponent;