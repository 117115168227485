import Subscribe from "../page/Subscribe";
import Login from "../page/Login";
import ValidateEmail from "../page/ValidateEmail";
import Coordinate from "../page/Coordinate";
import Graph from "../page/Graph";
import React from "react";
import Premium from "../page/Premium";
import ErrorPage from "../page/Error";
import ForgotPassword from "../page/ForgotPassword";
import SetPassword from "../page/SetPassword";
import User from "../page/User";
import Contact from "../page/Contact";
import Amount from "../page/Amount";
import ContactYourSponsor from "../page/ContactYourSponsor";

const routes =  [
    { path: '/', element: <Login /> , isSecure : false, reg: /\/$/, for: ['b2b', 'b2c', 'admin']},
    { path: '/subscribe', element: <Subscribe /> , isSecure: false, reg: /\/subscribe$/, for: ['b2b', 'b2c', 'admin']},
    { path: '/login', element: <Login /> , isSecure: false,reg: /\/login$/, for: ['b2b', 'b2c', 'admin']},
    { path: '/validate-account/:token', element: <ValidateEmail /> , isSecure: false, reg: /\/validate-account\/.+$/,  for: ['b2b', 'b2c', 'admin']},
    { path: '/secure/coordinate', element: <Coordinate /> , isSecure: true, reg: /\/secure\/coordinate$/, for: ['b2b', 'b2c']},
    { path: '/secure/graph/:lat/:lng', element: <Graph /> , isSecure : true, reg: /\/secure\/graph\/.+\/.+$/, for: ['b2b', 'b2c']},
    { path: '/premium/:email', element: <Premium /> , isSecure: false, reg: /\/premium\/.+$/, for: [ 'b2c']},
    { path: '/premium', element: <Premium email={false}/> , isSecure : false, reg: /\/premium$/, for: [ 'b2c']},
    { path: '/error', element: <ErrorPage /> , isSecure: false, reg: /^\/error$/, for: ['b2b', 'b2c','admin']},
    { path: '/forgot-password', element: <ForgotPassword /> , isSecure: false, reg: /\/forgot-password$/, for: ['b2b', 'b2c', 'admin']},
    { path: '/set-password/:token', element: <SetPassword /> , isSecure: false , reg: /\/set-passowrod\/.+$/, for: ['b2b', 'b2c', 'admin']},
    { path: '/admin/users', element: <User /> , isSecure: true , reg: /\/admin\/users/, for: ['admin']},
    { path: '/admin/amount', element: <Amount /> , isSecure: true , reg: /\/admin\/amount/, for: ['admin']},
    { path: '/contact', element: <Contact /> , isSecure: false , reg: /\/contact/, for: ['b2b', 'b2c']},
    { path: '/contact-your-sponsor', element: <ContactYourSponsor /> , isSecure: false , reg: /\/contact-your-sponsor/, for: ['b2b']},
];
export default routes;