import React, { useState, useEffect, useRef } from 'react';

const useHasChanged = (obj) => {
    // Référence pour stocker la valeur précédente de l'objet
    const prevObjRef = useRef();
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        const prevObj = prevObjRef.current;
        const before = prevObjRef.before;
        if (prevObj && before ) {
            if (!isEqual(before, obj)) {
                setHasChanged(true);
            } else {
                setHasChanged(false);
            }
        }
        prevObjRef.before = prevObj
        prevObjRef.current = obj;

    }, [obj]);

    const isEqual = (objA, objB) => {
        return JSON.stringify(objA) === JSON.stringify(objB);
    };

    return hasChanged;
}

export default useHasChanged;