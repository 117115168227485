import React from "react";
import {useDispatch, useSelector} from "react-redux";

const UserFilterAlert = () => {

    const dispatch = useDispatch();
    const alert = useSelector((state) => state.alert);

    const onChange = (e) => {
        dispatch({type: 'SET_ADMIN_ALERT', payload: e.target.value});
    }

    return (
        <>
            <select className="form-control" value={alert} onChange={onChange}>
                <option value="0">ALL</option>
                <option value="1">ALERT</option>
            </select>
        </>
    );
};

export default UserFilterAlert;