import React from "react";

const  LegendAbs = ({params}) => {

    let right = (params.x + params.bottomX - 158 - 36) + 'px';
    let bottom  = (params.y - 330.5 - 15) + 'px';



    return (
        <>
            {params.display  && (<div className="legend" style={{left: right, bottom: bottom}}>{params.content}</div>)}
        </>
    );
}
export default LegendAbs;