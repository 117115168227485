import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const ApplicationTypeFilter = () => {

    const dispatch = useDispatch();
    const applicationType = useSelector((state) => state.applicationType);

    const onChange = (e) => {
        dispatch({type: 'SET_ADMIN_CURRENT_APPLICATION_TYPE', payload: e.target.value});
    }

    return (
        <>
            <select value={applicationType} className="form-control" onChange={e => onChange(e)}>
                <option value="b2b">B2B</option>
                <option value="b2c">B2C</option>
            </select>
        </>
    )

}
export default ApplicationTypeFilter;