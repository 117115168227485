const initialState = {
    error: null,
    language: 'fr',
    email: null,
    applicationType: 'b2b',
    adminEmail: '',
    valid: 0,
    soc : '',
    country: 'ALL',
    alert: 0,
    startOrder: 'DESC',
    endOrder: 'DESC',
    createdAtOrder: 'DESC',

};

export const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ON_ERROR':
            return {
                ...state,
                error: action.payload,
            };

        case 'SET_LANGUAGE':
            return {
                ...state,
                language: action.payload,
            };
        case 'SET_EMAIL':
            return {
                ...state,
                email: action.payload,
            }
        case 'SET_ADMIN_CURRENT_APPLICATION_TYPE':
            return {
                ...state,
                applicationType: action.payload,
            }
        case 'SET_ADMIN_EMAIL':
            return {
                ...state,
                adminEmail: action.payload,
            }
        case 'SET_ADMIN_VALID':
            return {
                ...state,
                valid: action.payload,
            }
        case 'SET_ADMIN_SOC':
            return {
                ...state,
                soc: action.payload,
            }
        case 'SET_ADMIN_COUNTRY':
            return {
                ...state,
                country: action.payload,
            }

        case 'SET_ADMIN_ALERT':
            return {
                ...state,
                alert: action.payload,
            }
        case 'SET_ADMIN_ORDER_ENDORDER':
            return {
                ...state,
                endOrder: action.payload,
            }
        case 'SET_ADMIN_ORDER_STARTORDER':
            return {
                ...state,
                startOrder: action.payload,
            }
        case 'SET_ADMIN_ORDER_CREATEDATORDER':
            return {
                ...state,
                createdAtOrder: action.payload,
            }


        default:
            return state;
    }
};
