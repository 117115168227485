import React from "react";
import GenerateToken from "../components/GenerateToken";

const ForgotPassword = () => {

    return (
        <>
           <div className="page">
                <GenerateToken></GenerateToken>
            </div>
        </>
    );
}
export default ForgotPassword