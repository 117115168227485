// customPlugin.js
import {Chart as ChartJS, Chart, LineElement, Plugin} from 'chart.js';

const VerticalLine = {
    id: 'verticalLine',
    afterDraw: (chart, args, option, e ) => {
        // eslint-disable-next-line no-underscore-dangle
        let tooltip = chart.tooltip;
        if (tooltip._active && tooltip._active.length && (option.horizon === '15d' || option.horizon === '1d')) {
            // find coordinates of tooltip
            const activePoint = chart.tooltip._active[0];
            const { ctx } = chart;
            const { x } = activePoint.element;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;

            const index = tooltip.dataPoints[0].dataIndex;
            const  text = chart.config._config.data.labels[index].time;
            // draw vertical line
            ctx.save();
            ctx.restore();
            ctx.fillStyle = "rgba(75, 192, 192, 1)";
            ctx.fillRect(x - 50, bottomY, 100, 30);
            ctx.fillStyle = "black";
            ctx.fillText( text, x - 35, bottomY + 17 );
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#1C2128';
            ctx.stroke();

        }
    },

};

export default VerticalLine;