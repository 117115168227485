import React, {useEffect, useState} from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from "../http/axios";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const ChangePassword = ({isCaptcha}) => {

    const { t } = useTranslation();

    const [data, setData ] = useState({a: 0, b: 0});
    const [error , setError ] = useState('');
    const [ message, setMessage ] = useState('');


    const { token} = useParams();
    const navigate = useNavigate()

    const lang = useSelector((state) => state.language);


    const SignupSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, t('min_length_password'))
            .required(t('required')),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], t('password_match'))
            .required(t('required')),
    });

    return (
        <>
            { error && (<div className="alert alert-danger">{error}</div>)}
            { message && (<div className="alert alert-success">{message}</div>)}
            <Formik
                initialValues={{ password: '', confirm_password : '' }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setError('');
                    setMessage('');
                    axiosInstance.post('/change-password?token=' + token , values).then((response) => {

                        if(response.data.success) {
                            setMessage(t('password_changed'));
                            resetForm();
                            setTimeout(() => {
                                navigate('/' + lang + '/login');
                            },5000);
                        }
                        setSubmitting(false);
                    }).catch((error) => {
                        if( error.response.status === 404) setError(t('invalid token'));
                        if( error.response.status === 408) setError(t('expired_token'));
                        setSubmitting(false);
                    })


                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="password">{t('password')}</label>
                            <Field type="password" name="password" className="form-control"/>
                            <ErrorMessage name="password" component="div" style={{display: 'block'}}
                                          className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirrm_password">{t('confirm_password')}</label>
                            <Field type="password" name="confirm_password" className="form-control"/>
                            <ErrorMessage name="confirm_password" component="div" style={{display: 'block'}}
                                          className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-success form-control" type="submit" disabled={isSubmitting}>
                                {t('submit')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
export default ChangePassword;