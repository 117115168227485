import React from "react";
import ChangePassword from "../components/ChangePassword";

const SetPassword = () => {

    return (
        <>
            <div className="page">
                <ChangePassword></ChangePassword>
            </div>
        </>
    );
}
export default SetPassword