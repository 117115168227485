import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import routes from "../route/routes";
const useIsRouteSecure = () => {

    const [isSecure, setIsSecure ] = useState(false);

    const location = useLocation();
    useEffect(() => {
        const path = location.pathname;

        routes.forEach((element) => {
            if(path.match(element.reg)) {
                setIsSecure(element.isSecure);
            }
        })

    }, [location]);

    return isSecure;
}
export default useIsRouteSecure;