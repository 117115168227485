import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import axiosInstance from "../http/axios";


const AddCoordinate = ({setReload, reload }) => {

        const { t } = useTranslation();

    const validationSchema = Yup.object({
        lat: Yup.string().matches(/^-?\d+(\.\d+)?$/, t('must_be_number')).required(t('latitude_required')),
        long: Yup.string().matches(/^-?\d+(\.\d+)?$/, t('must_be_number')).required(t('longitude_required')),
        label: Yup.string().required(t('required')),
    });


    return (
        <div className="container mt-5">
            <Formik
                initialValues={{ lat:  '', long:  '' , label: ''}}
                validationSchema={validationSchema}
                onSubmit={(values,{ setSubmitting, resetForm}) => {
                    const response = axiosInstance.post('/api/coordinate', values).then((response) => {
                        setReload(reload+1);
                        setSubmitting(false);
                        resetForm();

                    }).catch((error) => {
                        console.log(error);
                        setSubmitting(false);
                    });

                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="-row mb-3">
                            <div className="col">
                                <label htmlFor="lat" className="form-label">{t('latitude')}</label>
                                <Field
                                    type="text"
                                    name="lat"
                                    id="lat"
                                    className="form-control"
                                />
                                <ErrorMessage name="lat" component="div"  style={{display:'block'}} className="text-danger" />
                            </div>
                            <div className="col">
                                <label htmlFor="long" className="form-label">{t('longitude')}</label>
                                <Field
                                    type="text"
                                    name="long"
                                    id="long"
                                    className="form-control"
                                />
                                <ErrorMessage name="long" component="div" style={{display:'block'}} className="text-danger" />
                            </div>
                            <div className="col">
                                <label htmlFor="label" className="form-label">{t('label')}</label>
                                <Field
                                    type="text"
                                    name="label"
                                    id="label"
                                    className="form-control"
                                />
                                <ErrorMessage name="label" style={{display:'block'}} component="div" className="text-danger" />
                            </div>
                            <div className="col small-col container-submit">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-sm center-coordinate-button"
                                    disabled={isSubmitting}
                                >{t('submit')}</button>
                            </div>
                            <div className="col small-col"></div>
                        </div>

                    </Form>
                )}
            </Formik>
        </div>

    );
}
export default AddCoordinate;