import React from "react";
import AmountComponent from "../components/AmountComponent";

const Amount = () => {
    return (
        <div className="page">
            <AmountComponent></AmountComponent>
        </div>
    )
}
export default Amount;