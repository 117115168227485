import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import AddCoordinate from "../components/AddCoordinate";
import GetCoordinate from "../components/GetCoordinate";
import QuestionForm from "../components/QuestionForm";

const Coordinate = () => {

    const { t } = useTranslation();


    const [reload, setReload ] = useState(0);

    const openGoogleMaps = (lat, long) => {
        let suffix = '';
        if (lat && long) {
            suffix = `?q=${lat},${long}`;
        }
        const url = `https://www.google.com/maps${suffix}`;
        window.open(url, '_blank');
    };

    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    openGoogleMaps(latitude, longitude);

                },
                (err) => {
                    openGoogleMaps();
                }
            );
        } else {
            openGoogleMaps();
        }
    };


    return(
        <>
            <div className="page">

                <GetCoordinate reload={reload} setReload={setReload}></GetCoordinate>
                <AddCoordinate reload={reload} setReload={setReload}></AddCoordinate>
                <div className="choose-on-map"><button className="btn btn-dark" onClick={e=> handleGetLocation()}>{t('choose_on_map')}</button></div>
                <QuestionForm isCaptcha={false}></QuestionForm>
            </div>
        </>)
}
export default Coordinate;