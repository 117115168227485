import React, {useEffect, useState} from "react";
import axiosInstance from "../http/axios";
import {ErrorMessage, Field} from "formik";
import {useTranslation} from "react-i18next";
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
const GetCoordinate =  ({reload, setReload}) => {

    const navigate = useNavigate();

    const lang = useSelector((state) => state.language);

    const [coordinates , setCoordinates] = useState([]);

    const { t } = useTranslation();


    const visualize = (lat,lng ) => {
        navigate('/' + lang + '/secure/graph/' + lat + '/' + lng );
    }

    useEffect(() => {
        const response =  axiosInstance.get('/api/coordinate').then((response)=> {
            setCoordinates(response.data);

        }).catch((error) => {

        })
    },[reload])

    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const handleShow = (item) => {
        setItemToDelete(item);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setItemToDelete(null);
    };

    const handleDelete = () => {
        if (itemToDelete) {
            axiosInstance.delete('/api/coordinate/' + itemToDelete).then((response) => {
                if(response.data.success) {
                    setReload(reload+1);
                }
            }).catch((error) => {
                console.log(error);
            })
            console.log('Deleting item:', itemToDelete);
            // Perform the delete action here (e.g., make an API call)
        }
        handleClose();
    };

    return(
        <div className="container mt-5">
            {coordinates.map((coordinate) => {
                return (
                    <div className="-row mb-3" key={coordinate.id}>
                        <div className="col">
                            <label>{t('latitude')}</label>
                            <input
                                type="text"
                                name="lat"
                                id="lat"
                                className="form-control"
                                defaultValue={coordinate.latitude}
                            />

                        </div>
                        <div className="col">
                            <label>{t('longitude')}</label>
                            <input
                                type="text"
                                name="long"
                                id="long"
                                className="form-control"
                                defaultValue={coordinate.longitude}
                            />
                        </div>
                        <div className="col">
                            <label>{t('label')}</label>
                            <input
                                type="text"
                                name="label"
                                id="label"
                                className="form-control"
                                defaultValue={coordinate.label}
                            />
                        </div>
                        <div className="container-visualize-delete">
                            <div className="col small-col">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-sm center-coordinate-button-read"
                                    onClick={e=> visualize(coordinate.latitude, coordinate.longitude)}
                                >{t('visualize')}</button>
                            </div>
                            <div className="col small-col">
                                <button
                                    onClick={e=>handleShow(coordinate.id)}
                                    className="btn btn-danger btn-sm center-coordinate-button-read"
                                >{t('delete')}</button>
                            </div>
                        </div>
                        <ConfirmDeleteModal
                            show={showModal}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                        ></ConfirmDeleteModal>
                    </div>
                )
            })}
        </div>
    )
}
export default GetCoordinate;