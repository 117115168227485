import {useTranslation} from "react-i18next";

const ContactYourSponsor = () => {

    const { t, i18n } = useTranslation();

    return(<>
        <div className="page">{t('contact_your_sponsor')}</div>
    </>)
}
export default ContactYourSponsor;