import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import elLocale from 'i18n-iso-countries/langs/el.json';
import bgLocale from 'i18n-iso-countries/langs/bg.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import reLocale from 'i18n-iso-countries/langs/ro.json';
import roLocale from 'i18n-iso-countries/langs/ro.json';
import itLocale from 'i18n-iso-countries/langs/it.json';
import ptLocale from 'i18n-iso-countries/langs/pt.json';
import esLocale from 'i18n-iso-countries/langs/es.json';


// You can add more locales as needed
countries.registerLocale(enLocale);
countries.registerLocale(frLocale);
countries.registerLocale(deLocale);
countries.registerLocale(elLocale);
countries.registerLocale(bgLocale);
countries.registerLocale(plLocale);
countries.registerLocale(reLocale);
countries.registerLocale(roLocale);
countries.registerLocale(itLocale);
countries.registerLocale(ptLocale);
countries.registerLocale(esLocale);

// Function to get country names in a specific language
export const getCountryOptions = (lang = 'en') => {
    const countryNames = countries.getNames(lang);
    return Object.entries(countryNames).map(([code, name]) => ({ code, name }));
};