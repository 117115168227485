import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['fr'];

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng,
        supportedLngs: [
            'fr',
            'de',
            'en',
            'el',
            'bg',
            'pl',
            'ro',
            'it',
            'pt',
            'es'
        ], // langues supportées
        debug: false,
        interpolation: {
            escapeValue: false, // react protège déjà contre XSS
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json', // chemin pour les fichiers de traduction
        },
    });

export default i18n;