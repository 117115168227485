import React from "react";
import {isArray} from "chart.js/helpers";
import {isDateBetween} from "../../tools/date";
import {Buffer} from 'buffer';

const UserApiLog = ({data}) => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);


    let connectionApiNumberInCurrentMonth;
    try {
        const json = JSON.parse(Buffer.from(data.data).toString());

        if (isArray(json)) {
            connectionApiNumberInCurrentMonth = json.filter((element) => isDateBetween(new Date(element.timestamp), startOfMonth, endOfMonth)).length;
        } else {
            connectionApiNumberInCurrentMonth = 0;
        }
    } catch(e) {
        connectionApiNumberInCurrentMonth = 0;
    }

    return (
        <div>{connectionApiNumberInCurrentMonth}</div>
    );
};

export default UserApiLog;